<script setup lang="ts">
const { t } = useT();

const prepareImgUrl = useImage();
const { open } = useAppModals();
</script>

<template>
	<div class="wheel-banner">
		<div class="wrapper">
			<div>
				<picture>
					<source
						:srcset="prepareImgUrl('/nuxt-img/wheel-banner/bg-mob.png', { format: 'webp', width: 300, height: 476 })"
						format="webp"
						width="300"
						height="476"
						media="(max-width: 767px)"
					/>
					<NuxtImg
						src="/nuxt-img/wheel-banner/bg-lg.jpg"
						format="webp"
						width="1108"
						height="421"
						class="back-img"
						alt="background"
						loading="lazy"
					/>
				</picture>
				<picture>
					<source
						:srcset="
							prepareImgUrl('/nuxt-img/wheel-banner/leaves-mob.png', { format: 'webp', width: 600, height: 588 })
						"
						format="webp"
						width="300"
						height="295"
						media="(max-width: 767px)"
					/>
					<NuxtImg
						src="/nuxt-img/wheel-banner/left-leaves.png"
						format="webp"
						width="337"
						height="420"
						class="leaves-left"
						alt="leaves"
						loading="lazy"
					/>
				</picture>
				<NuxtImg
					src="/nuxt-img/wheel-banner/right-leaves.png"
					format="webp"
					width="362"
					height="420"
					class="leaves-right"
					alt="leaves"
					loading="lazy"
				/>
				<NuxtImg
					src="/nuxt-img/wheel-banner/coins.png"
					format="webp"
					width="500"
					height="421"
					class="coins"
					alt="coins"
					loading="lazy"
				/>
				<NuxtImg
					src="/nuxt-img/wheel-banner/coins.png"
					format="webp"
					width="500"
					height="421"
					class="coins"
					alt="coins"
					loading="lazy"
				/>
				<div class="wheel-holder">
					<NuxtImg
						src="/nuxt-img/wheel-promo/circle@2x.png"
						format="webp"
						class="circle"
						alt="circle"
						width="725"
						height="724"
						loading="lazy"
					/>
					<NuxtImg
						src="/nuxt-img/wheel-promo/arrow.png"
						format="webp"
						alt="arrow"
						class="arrow"
						width="88"
						height="120"
						loading="lazy"
					/>
					<AAnimationSpin :duration="'9s'">
						<NuxtImg
							src="/nuxt-img/wheel-promo/wheel.png"
							format="webp"
							class="carousel"
							alt="wheel"
							width="898"
							height="901"
							loading="lazy"
						/>
					</AAnimationSpin>
					<NuxtImg
						src="/nuxt-img/wheel-promo/spin.png"
						format="webp"
						class="spin"
						alt="spin"
						width="103"
						height="103"
						loading="lazy"
					/>
					<NuxtImg
						src="/nuxt-img/wheel-banner/front-leaves.png"
						format="webp"
						class="leaves"
						alt="leaves"
						width="268"
						height="408"
						loading="lazy"
					/>
					<NuxtImg
						src="/nuxt-img/wheel-promo/sector.png"
						format="webp"
						class="sector"
						alt="sector"
						width="652"
						height="651"
						loading="lazy"
					/>
				</div>
			</div>
			<picture>
				<source
					:srcset="
						prepareImgUrl('/nuxt-img/wheel-banner/title-wheel-mob@2x.png', { format: 'webp', width: 500, height: 200 })
					"
					format="webp"
					width="500"
					height="200"
					media="(max-width: 767px)"
				/>
				<NuxtImg
					src="/nuxt-img/wheel-banner/title-wheel-lg.png"
					format="webp"
					width="698"
					height="86"
					class="title-wheel"
					alt="title"
					loading="lazy"
				/>
			</picture>
			<div class="content">
				<div class="title-block">
					<div>
						<AText variant="taiz" as="h3" :modifiers="['uppercase']" class="title">{{ t("REWARDS EVERY DAY!") }}</AText>
						<AText variant="tangier" as="p" :modifiers="['bold']" class="subtitle">{{
							t("Create an account and get your prize now!")
						}}</AText>
					</div>
					<AButton variant="primary" size="xl" class="button" @click="open('LazyOModalSignup')">{{
						t("Create an account")
					}}</AButton>
				</div>
			</div>
		</div>
		<NuxtImg
			src="/nuxt-img/wheel-banner/front-leaves.png"
			format="webp"
			width="268"
			height="408"
			class="leaves-front"
			alt="leaves"
			loading="lazy"
		/>
		<AButton variant="primary" size="xl" class="button footer" @click="open('LazyOModalSignup')">{{
			t("Create an account")
		}}</AButton>
	</div>
</template>

<style scoped lang="scss">
.wheel-banner {
	position: relative;
	height: 420px;

	@include media-breakpoint-down(lg) {
		margin: 0 gutter(2);
	}

	@include media-breakpoint-down(md) {
		height: 500px;
	}

	@include media-breakpoint-up(md) {
		overflow: hidden;
	}

	.back-img,
	.leaves-left,
	.leaves-right,
	.coins {
		position: absolute;
		top: 0;
		bottom: 0;
	}

	.back-img {
		left: 0;
		right: 0;
		width: 100%;

		@include media-breakpoint-down(md) {
			bottom: 24px;
		}
	}

	.leaves-left {
		left: 0;
		width: 337px;

		@include media-breakpoint-down(md) {
			width: 300px;
			right: 0;
			top: auto;
			height: auto;
			max-height: 295px;
		}
	}

	.leaves-right {
		right: 0;

		@include media-breakpoint-down(md) {
			display: none;
		}
	}

	.leaves-front {
		display: none;
		position: absolute;
		right: -28px;
		bottom: -16px;
		z-index: 1;
		animation: levitate 9s ease-in-out infinite;

		@include media-breakpoint-down(md) {
			width: 134px;
			height: 204px;
			display: block;
		}
	}

	.wheel {
		position: absolute;
		left: 0;
		bottom: 0;

		@media (max-width: 1365px) {
			max-width: 430px;
			height: auto;
		}

		@media (max-width: 1199px) {
			display: none;
		}

		@include media-breakpoint-down(lg) {
			display: block;
			left: 50%;
			transform: translate(-50%, 0);
		}

		@include media-breakpoint-down(md) {
			width: 362px;
			height: 220px;
		}
	}

	.wheel-holder {
		position: absolute;
		width: 100%;
		max-width: 340px;
		height: auto;
		bottom: 0;
		left: 50%;
		transform: translate(-50%, 50%) scale(1.1);

		@include media-breakpoint-up(md) {
			left: 46%;
		}

		@include media-breakpoint-up(lg) {
			left: 10px;
			transform: translateY(50%);
			max-width: 480px;
		}

		.carousel,
		.circle {
			width: 100%;
			height: auto;
		}

		.spin,
		.circle,
		.arrow,
		.leaves,
		.sector {
			position: absolute;
			height: auto;
		}

		.spin {
			position: absolute;
			z-index: 1;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);

			@include media-breakpoint-up(lg) {
				transform: translate(-50%, -50%) scale(1.4);
			}
		}

		.sector {
			left: 50%;
			transform: translateX(-49%);
			width: 86%;
			top: -10%;
		}

		.arrow {
			left: 50%;
			transform: translateX(-50%);
			width: 40px;
			top: -11px;
			z-index: 2;

			@include media-breakpoint-up(lg) {
				width: 55px;
				top: -15px;
			}
		}

		&::before {
			@include media-breakpoint-up(md) {
				content: "";
				display: block;
				position: absolute;
				bottom: 50%;
				left: 0;
				right: 0;
				height: 80px;
				background: var(--graz);
				z-index: 2;
			}

			@include media-breakpoint-up(lg) {
				height: 120px;
			}
		}

		.leaves {
			display: none;
			z-index: 3;
			animation: levitate 9s ease-in-out infinite;

			@include media-breakpoint-up(md) {
				right: -24%;
				display: block;
				bottom: 15%;
				width: 200px;
				height: auto;
				rotate: 22deg;
			}

			@include media-breakpoint-up(lg) {
				right: -24%;
				bottom: 25%;
				width: 230px;
			}
		}
	}

	.title-wheel {
		margin: 0 auto;
		display: block;
		position: relative;
		z-index: 2;

		@include media-breakpoint-down(md) {
			width: 250px;
			height: 100px;
		}
	}

	.coins {
		left: 0;
		z-index: 1;
		animation: levitate 8s ease-in-out infinite;

		@include media-breakpoint-down(md) {
			display: none;
		}
	}

	.wrapper {
		position: relative;
		width: 100%;
		height: 100%;
		overflow: hidden;
		padding-top: gutter(8);
		padding-bottom: 0;
		border-radius: 12px;

		@include media-breakpoint-down(md) {
			padding: gutter(3) gutter(2);
			height: calc(100% - 24px);

			&::before {
				content: "";
				display: block;
				position: absolute;
				bottom: 0;
				left: 0;
				right: 0;
				height: 156px;
				background: var(--graz);
				z-index: 1;
			}
		}
	}

	.content {
		display: flex;
		justify-content: flex-start;
		margin-left: 580px;
		position: relative;
		z-index: 2;

		@media (max-width: 1365px) {
			margin-left: 400px;
		}

		@media (max-width: 1199px) {
			margin-left: gutter(7);
		}

		@include media-breakpoint-down(md) {
			margin-left: 0;
			flex: 1;
		}
	}

	.title-block {
		flex: 1;
		display: flex;
		flex-direction: column;
		gap: gutter(4);

		@include media-breakpoint-down(md) {
			align-items: center;
			margin-top: gutter(1.5);
		}

		> div {
			max-width: 438px;
			display: flex;
			flex-direction: column;
			gap: gutter(1.5);

			@include media-breakpoint-down(md) {
				align-items: center;
				text-align: center;
				gap: gutter(0.5);
			}
		}
	}

	.title,
	.subtitle {
		position: relative;
		z-index: 2;
		text-shadow:
			0 10px 10px rgba(var(--corlu-rgb), 0.2),
			0 20px 25px rgba(var(--corlu-rgb), 0.1);
	}

	.title {
		@include media-breakpoint-down(md) {
			font-size: 24px;
			line-height: 30px;
		}
	}

	.subtitle {
		@include media-breakpoint-down(md) {
			font-size: 20px;
			line-height: 26px;
		}
	}

	.button {
		width: 256px;

		&.footer {
			display: none;
		}

		@include media-breakpoint-down(md) {
			display: none;

			&.footer {
				display: flex;
				position: absolute;
				bottom: 0;
				left: 50%;
				transform: translate(-50%, 0);
				z-index: 2;
			}
		}
	}
}

@keyframes levitate {
	0% {
		transform: rotate(0);
	}

	50% {
		transform: rotate(-5deg) scale(1.05);
	}

	100% {
		transform: rotate(0);
	}
}
</style>
